/* This example requires Tailwind CSS v2.0+ */
import {
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    PencilAltIcon,
    ReplyIcon,
    TrashIcon,
    UsersIcon,
  } from '@heroicons/react/outline'

  import PlumberImage from '../../../images/plumber.svg'
  import PlumbingImage from '../../../images/plumbing.png'
  import PlungerImage from '../../../images/plunger.png'
  import HeaterImage from '../../../images/heater.png'
  
  const features = [
    {
      name: 'Plomberie',
      description: `Chauffagiste Belgium, vous propose ses services, pour tous vos travaux de plomberie.`,
      icon: PlumbingImage,
    },
    {
      name: 'Débouchage',
      description: `Chauffagiste Belgium, met ses compétences à votre service, pour tous vos débouchages d’égouts.`,
      icon: PlungerImage,
    },
    {
      name: 'Chauffage',
      description: `Installation chauffage et sanitaire, dépannages, transformations, réparations.`,
      icon: HeaterImage,
    },
  ]
  
  export default function Example() {
    return (
      <div className="bg-blue-500">
        <div className="max-w-4xl mx-auto px-4 py-4 sm:px-6  sm:pb-24 lg:max-w-7xl lg:px-8">
          {/* <h2 className="text-3xl font-extrabold text-white tracking-tight">Inbox support built for efficiency</h2>
          <p className="mt-4 max-w-3xl text-lg text-blue-200">
            Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis. Blandit aliquam
            sit nisl euismod mattis in.
          </p> */}
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} data-aos="fade-up">
                <div className="flex justify-center">
                  <span className="flex items-center justify-center h-12 w-12 rounded-md  bg-opacity-10">
                    <img src={feature.icon}/>
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-white text-center">{feature.name}</h3>
                  <p className="mt-2 text-base text-blue-200">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  
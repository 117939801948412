/* This example requires Tailwind CSS v2.0+ */
import plumbingImage from '../../../images/plombier-pro-600x400.jpeg'
export default function Example() {
    return (
      <div className="relative bg-indigo-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={plumbingImage}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-75 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Débouchage</h1>
          <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
          Chauffagiste Belgium, met ses compétences à votre service, pour tous vos débouchages d’égouts et de canalisation.<br/><br/>
              Nos équipes composées de professionnels, réalisent tous vos travaux de débouchage sur tous types de canalisations.<br/><br/> 
              Notre expérience et nos compétences vous assurent un service sur mesure, alliant efficacité et rapidité d’exécution.<br/><br/>
              Afin de vous garantir des prestations irréprochables, et un résultat optimal, nous utilisons des matériaux professionnel à la pointe de la technologie et des techniques de qualité.<br/><br/>
              De plus, nous intervenons 7j/7 et 24h/24 pour vos urgences! Faites-appel à Chauffagiste Belgium ! <a href="tel:0032470178102" className='inline-block animate-beating text-blue-500'>0470/17.81.02</a>

            {/* Besoin d'une urgence ou d’un dépannage plomberie nos plombier sont spécialiser dans le domaine de l’urgence et dépannage plomberie<br/><br/>
            Nous sommes disponibles 24/24 7J7 possibilité d’intervention en 30 minutes, rendez-vous possible tous les jours. 
            Pour tous renseignement n’hésitez pas à nous contacter au <span className='inline-block animate-beating text-blue-500'>0470/17.81.02</span> */}
            {/* Chauffagiste Belgium vous propose ses services pour tous vos travaux de plomberie.<br/><br/>

            Vous rêvez d'une nouvelle salle de bains clés en main ? 
            Faites appel à l'entreprise qui réalise la mise en place de vos sanitaires, 
            de vos chauffe-eau thermodynamiques, qui procède, en cas de besoin, 
            à l'inspection vidéo de vos canalisations d'assainissement.<br/><br/>

            Présent partout en Europe , Chauffagiste Belgium intervient 
            rapidement pour vos dépannages de plomberie et le remplacement de votre installation.<br/><br/> */}
          </p>
        </div>
      </div>
    )
  }
  
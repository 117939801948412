/* This example requires Tailwind CSS v2.0+ */
const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Employees', value: '5' },
    { label: 'Beta Users', value: '521' },
    { label: 'Raised', value: '$25M' },
  ]
  import PlumbingImage from '../../../images/heating3.jpg'
  import PlumberImage2 from '../../../images/heating-central-gas-furnace-AFRW6SQ-825x340.jpeg'
  
  export default function Example() {
    return (
      <div className="relative bg-white py-16 sm:py-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={PlumbingImage}
                  alt=""
                />
                <div className="absolute inset-0 bg-red-400 mix-blend-multiply" />
                <div className="absolute inset-0 bg-gradient-to-t from-red-500 via-red-500 opacity-90" />
                <div className="relative px-8">
                  {/* <div>
                    <img
                      className="h-12"
                      src="https://tailwindui.com/img/logos/workcation.svg?color=white"
                      alt="Workcation"
                    />
                  </div> */}
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      {/* <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg> */}
                      <p className="relative">
                        Votre chauffagiste réalise le contrôle de vos installations au gaz. Chauffagiste Belgium, intervient sur tous modèles de chaudières : Bulex, Vaillant, Buderus, Junkers, Voisement, Chaffoteaux, Remeha De Dietrich., De Dietrich, Remeha… 
                      </p>
                    </div>
  
                    {/* <footer className="mt-4">
                      <p className="text-base font-semibold text-red-200">Sarah Williams, CEO at Workcation</p>
                    </footer> */}
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
  
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 data-aos="fade-down" className="text-red-700 text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Chauffage
              </h2>
                <div data-aos="fade-right" className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                Nos chauffagiste , font l’entretien et le dépannage, spécialiser sur tous types et marques de chaudières, murales et au sol, également votre chauffe-eaux et chauffe-bains
            . Besoin d’une intervention rapide, et efficace en moins de 24h dans votre régions n’hésitez pas a nous contacter au <span className='inline-block animate-beating text-red-400'>0470/17.81.02</span>
                

                {/* votre chauffagiste réalise le contrôle de vos installations au gaz. Chauffagiste Belgium, intervient sur tous modèles de chaudières : Bulex, Vaillant, Buderus, Junkers, Voisement, Chaffoteaux, Remeha De Dietrich., De Dietrich, Remeha… */}
                </div>
            </div>
  
            
          </div>
        </div>
        <div className='px-6 pt-8 block xs:hidden'>
          <img src={PlumberImage2} className='rounded-lg shadow-lg object-cover object-center'/>
        </div>
      </div>
    )
  }
  
/* This example requires Tailwind CSS v2.0+ */
import plumbingImage from '../../../images/heating.jpg'
export default function Example() {
    return (
      <div className="relative bg-indigo-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={plumbingImage}
            alt=""
          />
          <div className="absolute inset-0 bg-black opacity-75 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl lg:text-5xl">Dépannage de votre chaudière en moins de 24h !</h1>
          <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
            Parce qu’une panne peut survenir à tout moment, Chauffagiste Belgium, assure un dépannage de votre chaudière en moins de 24h ! Un service de qualité proposé dans toute la Belgique.<br/><br/>

            Un technicien chauffagiste à votre service son savoir-faire afin de vous garantir un service irréprochable depuis plus de 10 ans. Pour garantir votre sécurité et vous permettre de réduire votre consommation énergétique .<br/><br/>
            Pour tous renseignement n’hésitez pas à nous contacter au <a href="tel:0032470178102" className='inline-block animate-beating text-red-500'>0470/17.81.02</a>
            {/* ALLOeXPERTservice vous propose ses services pour tous vos travaux de plomberie.<br/><br/>

            Vous rêvez d'une nouvelle salle de bains clés en main ? 
            Faites appel à l'entreprise qui réalise la mise en place de vos sanitaires, 
            de vos chauffe-eau thermodynamiques, qui procède, en cas de besoin, 
            à l'inspection vidéo de vos canalisations d'assainissement.<br/><br/>

            Présent partout en Europe , ALLOeXPERTservice intervient 
            rapidement pour vos dépannages de plomberie et le remplacement de votre installation.<br/><br/> */}
          </p>
        </div>
      </div>
    )
  }
  
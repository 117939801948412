import React, {useEffect} from 'react' 
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'

import Menu from './sections/Menu'
import Hero from "./sections/Hero"
import Services from './sections/Services'
import Cta from './sections/Cta'
import Brand from './sections/Brand'
import Ceo from './sections/Ceo'
import Phone from "../../french/components/Phone"
import Mail from "../../french/components/Email"
import Sponsor from '../home/sections/Sponsor'

import Possibilities from '../home/sections/Possibilities'
import Logo from '../home/sections/Logo'

const Heating = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    })

    return(
        <motion.div
            initial={{scaleX: 0}}
            animate={{scaleX: 1}}
            exit={{scaleX: 0}}
            transition={{duration:0.5}}
        >
            <React.Fragment>
                <Phone/>
                {/* <Mail/> */}
                <Logo/>
                <Menu/>
                <Possibilities/>
                <Hero/>
                <Services/>
                <Cta/>
                <Brand/>
                <Sponsor/>
                <Ceo/>
            </React.Fragment>
        </motion.div>
    )
}

export default Heating
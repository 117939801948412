/* This example requires Tailwind CSS v2.0+ */
const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Employees', value: '5' },
    { label: 'Beta Users', value: '521' },
    { label: 'Raised', value: '$25M' },
  ]
  import PlumbingImage from '../../../images/plumbing2.jpg'
  import Heather from '../../../images/heater2.jpeg'
  
  export default function Example() {
    return (
      <div className="relative bg-white pb-16 sm:pb-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className='order-last self-start'>
            <div className="relative sm:py-16 lg:py-0 hidden lg:block">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={Heather}
                  alt=""
                />
                {/* <div className="absolute inset-0 bg-blue-400 mix-blend-multiply" /> */}
                {/* <div className="absolute inset-0 bg-gradient-to-t from-blue-500 via-blue-500 opacity-90" /> */}
                <div className="relative px-8">
                  {/* <div>
                    <img
                      className="h-12"
                      src="https://tailwindui.com/img/logos/workcation.svg?color=white"
                      alt="Workcation"
                    />
                  </div> */}
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      {/* <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg> */}
                      {/* <p className="relative">
                      Besoin d'une réparation, plomberie que sa soit une réparation de siphon, fuite au niveau d’un raccord ou tuyauterie une réparation de fuite d’eau, ou autre…) N’hésitez pas a nous contactez au 0470 178 102, nous sommes à votre disposition 24H24 7J7.
                      </p> */}
                    </div>
  
                    {/* <footer className="mt-4">
                      <p className="text-base font-semibold text-blue-200">Sarah Williams, CEO at Workcation</p>
                    </footer> */}
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          </div>
          
  
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              
              
            <div className="relative py-12 sm:py-16 lg:py-0 block lg:hidden">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />

            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={Heather}
                  alt=""
                />
                {/* <div className="absolute inset-0 bg-blue-400 mix-blend-multiply" /> */}
                {/* <div className="absolute inset-0 bg-gradient-to-t from-blue-500 via-blue-500 opacity-90" /> */}
                <div className="relative px-8">
                  {/* <div>
                    <img
                      className="h-12"
                      src="https://tailwindui.com/img/logos/workcation.svg?color=white"
                      alt="Workcation"
                    />
                  </div> */}
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      {/* <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg> */}
                      {/* <p className="relative">
                      Besoin d'une réparation, plomberie que sa soit une réparation de siphon, fuite au niveau d’un raccord ou tuyauterie une réparation de fuite d’eau, ou autre…) N’hésitez pas a nous contactez au 0470 178 102, nous sommes à votre disposition 24H24 7J7.
                      </p> */}
                    </div>
  
                    {/* <footer className="mt-4">
                      <p className="text-base font-semibold text-blue-200">Sarah Williams, CEO at Workcation</p>
                    </footer> */}
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
              <h2 data-aos="fade-down" className="text-blue-500 text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Nous dépannons toutes les marques
              </h2>
                <div className="mt-5 prose prose-blue text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <ul className="list">
                    <li data-aos="fade-right">Dépannage chaudière vaillant</li>
                    <li data-aos="fade-right">Dépannage chaudière bulex</li>
                    <li data-aos="fade-right">Dépannage chaudière junkers</li>
                    <li data-aos="fade-right">Dépannage chaudière elco</li>
                    <li data-aos="fade-right">Dépannage chaudière oertli</li>
                    <li data-aos="fade-right">Dépannage chaudière viessmann</li>
                    <li data-aos="fade-right">Dépannage chaudière buderus</li>
                    <li data-aos="fade-right">Dépannage chaudière riello</li>
                    <li data-aos="fade-right">Dépannage chaudière brotje</li>
                    <li data-aos="fade-right">Dépannage chaudière chappee</li>
                    <li data-aos="fade-right">Dépannage chaudière acv</li>
                    <li data-aos="fade-right">Dépannage chaudière sime</li>
                    <li data-aos="fade-right">Dépannage chaudière ferroli</li>
                    <li data-aos="fade-right">Dépannage chaudière ariston</li>
                    <li data-aos="fade-right">Dépannage chaudière radson</li>
                    <li data-aos="fade-right">Dépannage chaudière chaffoteaux et maury</li>
                    <li data-aos="fade-right">Dépannage chaudière de Dietrich</li>
                    <li data-aos="fade-right">Dépannage chaudière weishaupt</li>
                    <li data-aos="fade-right">Dépannage chaudière wolf</li>
                    <li data-aos="fade-right">Dépannage chaudière remeha</li>
                    <li data-aos="fade-right">Dépannage chaudière ideal standard</li>
                    <li data-aos="fade-right">atlantic - grunfos - wilo - Honeywell</li>
                    </ul>
                </div>
            </div>
  
            
          </div>
        </div>
      </div>
    )
  }
  
import { Fragment } from 'react'
import {Link} from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import Possibilities from './Possibilities'
import PossibilitiesBig from './PossibilitiesBig'

import HeroImage from '../../../images/hero.jpg'
import logo from '../../../images/logo2.png'

const navigation = [
  { name: 'Acceuil', href: '/fr/acceuil' },
  { name: 'Plomberie', href: '/fr/plomberie' },
  { name: 'Débouchage', href: '/fr/debouchage' },
  { name: 'Chauffage', href: '/fr/chauffage' },
  // { name: 'Contact', href: "#contact" },
]

export default function Example() {
  return (
    <div className="relative bg-white overflow-hidden z-0">
      <div className="max-w-7xl mx-auto">
        <div className="relative pb-0 z-10 xs:pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100"/>
          </svg>

          <Popover>
            <div className="relative pt-0 xs:pt-6 px-0 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-start xs:justify-between w-full md:w-auto bg-blue-500 xs:bg-white">
                    <a href="/fr/acceuil" className="xs:block hidden ">
                      <span className="sr-only">Workflow</span>
                      <img
                        className="h-8 w-auto sm:h-10"
                        src={logo}
                      />
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="bg-transparent  rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 ">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                    <div className="xs:hidden block justify-self-start ml-2 font-medium text-white">Menu</div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8 ">
                  {navigation.map((item) => (
                    <Link key={item.name} to={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </Link>
                  ))}
                  <a href="/fr/acceuil#contact" className="font-medium text-blue-600 hover:text-blue-500">
                    Contact
                  </a>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-40 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div className="xs:block hidden">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <a
                    href="/fr/acceuil#contact"
                    className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100"
                  >
                    Contact
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Possibilities/>
          <PossibilitiesBig/>   
          <main className="z-0 pb-12 xs:pb-0 mt-0 xs:mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-14 lg:px-8 xl:mt-8  xs:bg-white">
            <div className="sm:text-center lg:text-left">
              <div className='justify-center items-center xs:flex hidden'>
                <img src={logo} className='h-36'/>
              </div>
              <h1 className="flex items-center flex-col text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl mt-6 xs:mt-0">
                <span className="xs:block hidden xl:inline text-center" data-aos="fade-right" data-aos-duration="2000"><span className='text-black'>Chauff</span><span className='text-yellow-400'>agiste</span> <span className='text-red-700'>Belgium</span></span>{' '}
                
                <div className="xs:hidden block aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={HeroImage}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
                
                <span className="comic  hidden xs:block  text-blue-500 text-lg lg:text-3xl mt-6" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="2000">Un service de qualité, en toute rapidité !</span>
                <a href="tel:0032470178102" className="block text-blue-500 xl:inline" data-aos="fade-right" data-aos-delay="1500" data-aos-duration="2000">0470/17.81.02</a>
              </h1>
              <p className="mt-3 text-base text-black xs:text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {/* Notre équipe SPÉCIALISER est disponible 24/7 POUR TOUTE URGENCE.<br/><br/>
                Possibilité d’intervention en (30 min !!) ou besoin d'un rendez vous contactez nous au&nbsp;
                <span className="inline-block text-blue-500 font-bold text-2xl animate-beating">0474/391/856</span><br/><br/> */}
                  Chauffagiste Belgium, spécialiser dans le dépannage sanitaire, chauffage et débouchage.<br/><br/>

                  Pour tous renseignements Chauffagiste Belgium est a votre disposition 24h24 7J7.<br/><br/>

                  Possibilité d’intervention en 30 min, pour une urgence.<br/>

                  Besoin d’un rendez-vous , possibilité tous les jours.<br/>
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to="/fr/plomberie"
                    className=" w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                  >
                    Plomberie
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to="/fr/chauffage"
                    className=" w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 md:py-4 md:text-lg md:px-10"
                  >
                    Chauffage
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    to="/fr/debouchage"
                    className=" w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-yellow-600 md:py-4 md:text-lg md:px-10"
                  >
                    Débouchage
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 xs:block hidden ">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={HeroImage}
          alt=""
        />
      </div>
    </div>
  )
}

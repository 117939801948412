/* This example requires Tailwind CSS v2.0+ */
import {Link} from 'react-router-dom'
import { ExternalLinkIcon } from '@heroicons/react/solid'

import CallPhoto from '../../../images/call.png'

export default function Example() {
  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src={CallPhoto}
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          {/* <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">Award winning support</h2> */}
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl" data-aos="fade-right">Pourquoi nous choisir ?</p>
          <p className="mt-3 text-lg text-gray-300" data-aos="fade-right">
            En matière de plomberie, débouchage et de chauffage, la société Chauffagiste Belgium, est un expert incontournable. Nous réparons les installations existantes qu'elle en soit la marque. Mais également pour tout nouvel équipement sanitaire, nous pouvons vous conseiller. Notre savoir-faire dans ce domaine permet de répondre au besoin le plus particulier. Qu'il s'agisse d'une réparation, d'une nouvelle installation ou d'une extension, demandez conseil à Chauffagiste Belgium.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:0032470178102"
                className="animate-bounce inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Nous contacter
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/* This example requires Tailwind CSS v2.0+ */
import {ClockIcon, ClipboardListIcon, AcademicCapIcon, ChatAltIcon} from '@heroicons/react/outline'

const perks = [
  { name: `Plus de 10 ans d'experience !`, description: 'Un travail de qualité', icon: AcademicCapIcon },
  { name: 'Nous intervenons en moins de 30 minutes !', description: 'Une intervention rapide !', icon: ClockIcon },
  { name: 'Nous traitons chaque cas avec grand interêt !', description: 'Une équipe à votre écoute', icon: ChatAltIcon },
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto divide-y divide-gray-200 lg:py-8 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x">
        {perks.map((perk) => 
          <div className="py-8 lg:py-0 lg:w-1/3 lg:flex-none" data-aos="zoom-in">
            <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
              <perk.icon className="flex-shrink-0 h-8 w-8 text-blue-500"/>
              <div className="ml-4 flex-auto flex flex-col-reverse ">
                <h3 className="font-medium text-gray-900  xl:truncate">{perk.name}</h3>
                <p className="text-sm text-gray-500">{perk.description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
